define([
  'jquery',
  'underscore',
  'communicator',
  'backbone',
  '../components/historyBreadcrumb',
],
($, _, Communicator, Backbone, HistoryBreadcrumb) => Backbone.Marionette.AppRouter.extend({
  proxyInitialized: false,

  acls: [],

  overwriteAcls: [],

  /**
             * If no controller is defined we use a proxy controller so the routes are initialized.
             * This is so we can still navigate to the routes but load the controller later on using webpack chunks.
             * @param options
             */
  constructor(options) {
    if (!this.controller && this.getController !== undefined) {
      var self = this;
      var appRoutes = this.getOption('appRoutes');
      var chunkLoaded = $.Deferred();

      this.controller = new Backbone.Marionette.Controller(options);

      this.controller.on('before', () => {
        if (!self.proxyInitialized) {
          self.getController((controller) => {
            self.realController = new controller(options);
            self.proxyInitialized = true;

            if (_.isFunction(self.realController.before)) {
              self.realController.before();
            }

            chunkLoaded.resolve();
          });
        } else if (_.isFunction(self.realController.before)) {
          self.realController.before();
        }
      });
    }

    _.each(appRoutes, (route) => {
      self.controller[route] = function () {
        const parameters = arguments;

        $.when(chunkLoaded.promise()).then(
          () => {
            self.realController[route].apply(self.realController, parameters);
          },
        );
      };
    });

    Backbone.Marionette.AppRouter.prototype.constructor.call(this, options);
  },

  before(route) {
    // handle the native page change protection
    let stopMe = false;
    if (window.onbeforeunload) {
      stopMe = window.onbeforeunload();
      if (stopMe) {
        console.warn(`[onbeforeunload] Changing route stopped for: ${route}`);
      }
    }

    const authorized = !stopMe && this.isAuthorized(route);
    const self = this;

    HistoryBreadcrumb.add(route, () => self.isAuthorized(route));

    this.fireAllEndMethods(route, authorized);

    return authorized;
  },

  isAuthorized(route) {
    let authorized = true;
    const acls = this._findAclsForRoute(route);
    $.each(acls, (i, acl) => {
      authorized = authorized && acl.authorized(route);
    });

    return authorized;
  },

  _findAclsForRoute(route) {
    let acls = $.extend(true, {}, this.acls);
    if (this.overwriteAcls.length) {
      $.each(this.overwriteAcls, (i, acl) => {
        if (acl.routes.indexOf(route) > -1) {
          acls = $.extend(true, {}, acl.acls);
        }
      });
    }
    return acls;
  },

  fireAllEndMethods(route, authorized) {
    const acls = this._findAclsForRoute(route);
    if (!authorized) {
      const oldRoute = HistoryBreadcrumb.getBackRoute();
      console.warn(`Route ${route} not authorized (back to:${oldRoute})`);
      // set the past route to prevent eternal loader/ broken page on reaload
      HistoryBreadcrumb.replaceCurrent(oldRoute);
    }
    $.each(acls, (i, acl) => {
      if (acl.authorized(route)) {
        if (typeof (acl.success) === 'function') {
          acl.success(route, authorized);
        }
      } else if (typeof (acl.error) === 'function') {
        acl.error(route, authorized);
      }
      if (typeof (acl.always) === 'function') {
        acl.always(route, authorized);
      }
    });
  },
}));
