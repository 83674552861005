define([
  './../components/component',
  'underscore',
  'jquery',
],
(Component, _, $) => {
  const idle = Component.extend({
    lastActive: new Date(),

    register() {
      const self = this;
      self.reset();

      $('body').on('click mousemove keyup touchend', () => {
        self.setLastActive();
      });
    },

    unregister() {
      $('body').off('click mousemove keyup touchend');
    },

    setLastActive: _.throttle(function () {
      this.lastActive = new Date();
    }, 1000),

    reset() {
      this.lastActive = new Date();
    },

    getLastActive() {
      return this.lastActive;
    },
  });

  return new idle();
});
