define([
  'jquery',
  'backbone',
  'modules/common/components/string',
  'modules/common/components/uuid',
  'modules/upx/components/upx',
  'modules/common/components/managers/configuration',
],
($, Backbone, String, Uuid, Upx, ConfigurationManager) => {
  const comp = Backbone.Marionette.Controller.extend({

    /**
             * Upload a blob
             * @param blob
             * @param fileName
             * @param adapterId
             * @param returnResponse
             * @param relationDataId
             * @returns {*}
             */
    uploadBlob(blob, adapterId, fileName, returnResponse, relationDataId) {
      const def = $.Deferred();
      const configuration = App.com(ConfigurationManager).get('upx');
      const fileKey = Uuid.genRandom();
      const options = {
        files: [{
          file_key: fileKey,
          adapter_id: adapterId,
          name: fileName,
          published: true,
        }],
      };

      if (relationDataId !== undefined) {
        options.files[0].relation_data_id = relationDataId;
      }

      $.when(Upx.call('FileModule', 'setMultipleFileUpload', options)).then(
        (response) => {
          const { secret } = response.upload_key;
          const keyId = response.upload_key.id;
          const url = `${configuration.get('server')
          }?action=upload&`
                            + `api=json&key_id=${keyId
                            }&key_secret=${secret}`
                            + `&account_name=${configuration.get('account')}`;

          const formData = new FormData();
          formData.append(fileKey, blob, fileName);

          const ajaxOptions = {
            type: 'POST',
            url,
            data: formData,
            processData: false,
            contentType: false,
          };

          $.when($.ajax(ajaxOptions)).then(
            (uploadResponse) => {
              if (returnResponse) {
                def.resolve(response);
              } else {
                def.resolve(uploadResponse.response[fileKey]);
              }
            },
          );
        },
      );

      return def.promise();
    },
  });

  return new comp();
});
