define([
  'application',
  'backbone',
  './../components/component',
  'backbone.localstorage',
],
(App, Backbone, Component) => {
  const AppLocalStorage = Component.extend({

    get(name, serializer) {
      return new Backbone.LocalStorage(
        `[${
          App.settings.uuid
        }]${name}`,
        serializer,
      );
    },

  });

  return new AppLocalStorage();
});
