define([
  'module',
  'backbone',
  'application',
  'underscore',
  '../cacheStore/localStorage',
  '../cacheStore/webSqlStorage',
],
(Module, Backbone, App, _, LocalStorage, WebSqlStorage) => {
  const man = Backbone.Marionette.Controller.extend({

    name: 'modules/common/components/managers/cache',

    initialize(options) {
      this.stores = [];
    },
    /**
             *
             * @param name
             * @param options
             * @returns {store}
             */
    getStore(name, options) {
      const cls = this.getStoreClsByPriority();
      options = options || {};
      options.name = name;
      // return new LocalStorage(options);
      return new cls(options);
    },
    /**
             *
             * @returns {*}
             */
    getStoreClsByPriority() {
      if (_.isEmpty(this.stores)) {
        throw new Error('No cache stores registered');
      }
      let cls = null;
      let priority = null;
      _.each(this.stores, (o, i) => {
        if (priority == null || priority < o.priority) {
          cls = o.cls;
          priority = o.priority;
        }
      });
      return cls;
    },
    /**
             *
             * @param cls
             * @param priority
             */
    registerStore(cls, priority) {
      this.stores.push({
        alias: cls.prototype.name,
        priority: priority || 0,
        cls,
      });
    },
  });

  return new man();
});
