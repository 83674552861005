define([
  'underscore',
  'hbs/handlebars',
  'modules/common/components/locale',
], (
  _, Handlebars, Locale,
) => {
  const fullRelationName = (relationObject) => {
    let returnName = '-';

    if (typeof relationObject === 'object') {
      const { isprivate: isConsumer, name = '', contact_person = {} } = relationObject;
      const { ismale = null } = contact_person;

      returnName = name;

      if (isConsumer) {
        let salutation = '';
        if (_.isBoolean(ismale)) {
          salutation = ismale ? Locale.translate('mr') : Locale.translate('mrs');
        }
        returnName = `${salutation} ${name}`.trim();
      }
    }

    return returnName;
  };

  Handlebars.registerHelper('common$fullRelationName', fullRelationName);

  return fullRelationName;
});
