define([
  'underscore',
  'backbone',
  'money-math',
  'modules/common/collections/currencies',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  _, Backbone, MoneyMath, CurrencyCollection,
  DefaultShopConfiguration,
) => {
  const currency = Backbone.Marionette.Controller.extend({

    Math: MoneyMath,

    defaults: {
      native_symbol: true,
      symbol_amount_space: true,
      space: ' ', // utf8 non breaking space
    },

    isoToSymbol(iso3, get_native_symbol) {
      get_native_symbol = get_native_symbol || true;

      let symbol;
      const currencyData = CurrencyCollection.getCurrencyObjectUsingIso3(iso3);

      symbol = currencyData.symbol;
      if (get_native_symbol) {
        symbol = currencyData.native_symbol;
      }

      return symbol;
    },

    /**
         *
         * @param value
         * @return number
         */
    currencyToFloat(value) {
      if (
        typeof value === 'string'
      ) {
        if (value.match(/^\-?\d+\.\d{4}$/)) {
          // proper money value
          return parseFloat(value);
        }
        value = value.replace(/\,/g, '###');
        value = value.replace(/\.([\d]{3})/g, '$1');
        value = value.replace(/\#\#\#/g, '.');

        // Removing non-currency characters;
        // only number, Commas and dots are allowed;
        value = value.replace(/[^0-9\,\.-]/g, '');

        // If someone tries to format words. it will reset to 0
        if (value === '') {
          value = 0;
        }
        return parseFloat(value) || 0;
      } if (typeof value === 'number') {
        return value;
      }

      return parseFloat(value);
    },

    toCurrency(value) {
      if (value === null || value === undefined || isNaN(value)) {
        console.warn(`toCurrency received ${value} as value`);
        return '0.00';// zero value if the input is invalid
      }
      return this.Math.floatToAmount(this.currencyToFloat(value));
    },

    formatAmount(iso3, amount) {
      // Check if amount is a string
      amount = parseFloat(amount);
      if (isNaN(amount)) {
        amount = '0.00';
      }

      // Making sure the amount is a string;
      if (typeof amount !== 'string') {
        amount = this.Math.floatToAmount(amount);
      }

      // Formatting the amount
      return this.Math.format(iso3, amount);
    },

    format(iso3, amount, config) {
      if (!iso3) {
        iso3 = DefaultShopConfiguration.getCurrencyIso3();
      }
      if (!iso3) {
        console.error(`No currency set for value: ${amount}, fallback to EUR`);
        iso3 = 'EUR';
      }
      config = config || {};
      config = _.extend({}, this.defaults, config);

      let symbol;
      let space;

      // getting the currencyData
      const currencyData = CurrencyCollection.getCurrencyObjectUsingIso3(iso3);
      if (!currencyData) {
        console.error(`No currency object found for ISO3: ${iso3}`);
        return this.toCurrency(amount);
      }

      // Setting the Symbol
      symbol = currencyData.symbol;
      if (config.native_symbol) {
        symbol = currencyData.symbol_native;
      }

      // creating space
      space = '';
      if (config.symbol_amount_space) {
        space = config.space;
      }

      return symbol + space + this.formatAmount(iso3, amount);
    },

    /**
         * @param a {number}
         * @param b {number}
         * @return {number}
         */
    multiply(a, b) {
      const aValue = this.toCurrency(a);
      const bValue = this.toCurrency(b);
      const sum = this.Math.mul(aValue, bValue);
      return this.currencyToFloat(sum);
    },

  });

  return new currency();
});
