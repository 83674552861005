define([
  'jquery',
  'underscore',
  'hbs/handlebars',
],
($, _, Handlebars) => {
  function render(duration_in_seconds) {
    const hours = (Math.floor(duration_in_seconds / 3600)).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });
    duration_in_seconds %= 3600;
    const minutes = (Math.floor(duration_in_seconds / 60)).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });
    const seconds = (duration_in_seconds % 60).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });

    return `${hours}:${minutes}:${seconds}`;
  }

  Handlebars.registerHelper('common$durationRender', render);
  return render;
});
