define([
  'jquery',
  './../components/component',
],
($, Component) => Component.extend({
  /**
             *
             * @param options
             */
  initialize(options) {
    Component.prototype.initialize.call(this, options);
    this.acls = options.acls || [];
  },
  /**
             *
             * @returns {boolean}
             */
  authorized() {
    let authorized = false;
    $.each(this.acls, (i, acl) => {
      authorized = authorized || acl.authorized();
    });
    return authorized;
  },
  /**
             *
             * @param route
             */
  error(route) {
    $.each(this.acls, (i, acl) => {
      if (acl.error) {
        acl.error(route);
        return false; // break the loop after first
      }
    });
  },
}));
