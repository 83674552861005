define([
  'modules/common/crontabs/cron',
  'modules/common/components/idle',
  'modules/common/models/idle',
],
(Cron, Idle, IdleModel) => Cron.extend({
  cron: '* * * * *',

  run() {
    const self = this;
    const lastActive = Idle.getLastActive();

    const model = new IdleModel();
    model.getLastActive(); // updates also the model
  },

}));
