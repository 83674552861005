define(['hbs/handlebars', 'modules/common/components/locale', 'modules/common/components/moment'], (Handlebars, Locale, Moment) => {
  function compare(datetime, locale, withTime = 'true') {
    if ((typeof locale === 'string' && locale.length === 0) || typeof locale !== 'string') {
      locale = Locale.getLocale();
    }

    if (withTime === 'true') {
      return Moment(datetime).locale(locale).calendar();
    }

    return Moment(datetime).locale(locale).calendar({
      sameDay: `[${Locale.translate('today')}]`,
      nextDay: `[${Locale.translate('tomorrow')}]`,
      nextWeek: 'dddd',
      lastDay: `[${Locale.translate('yesterday')}]`,
      lastWeek: `[${Locale.translate('last_{calendar_day}', {
        calendar_day: Moment(datetime).format('dddd'),
      })}]`,
      sameElse: 'LL',
    });
  }

  Handlebars.registerHelper('common$calendarTime', compare);
  return compare;
});
