define([
  'backbone',
  'underscore',
  '@storekeeper/translate',
  'modules/common/events/locale/loaded',
  'translate.json',
],
(Backbone, _,
  { Translator, LiveJSTranslator, WebEditor },
  LoadedEvent, TranslateJson) => {
  const locale = Backbone.Marionette.Controller.extend({
    initialize() {
      const supportedLocales = this.getSupportedLanguages();
      console.log(`[Locale] Supported locales: ${supportedLocales.join(',')}`);

      const live = WebEditor.getModeFromUrlQuery();
      if (live.enabled) {
        this.translator = new LiveJSTranslator(supportedLocales);
        console.log('[LiveJSTranslator] Enabled');
        // no need to add loader in the live mode
        if (live.language) {
          this.translator.forceLocale(live.language);
          console.log(`[LiveJSTranslator] Locale set to ${live.language}`);
        }
        this.editor = new WebEditor(this.translator);
        this.attachEditor();
      } else {
        this.translator = new Translator(supportedLocales);
        this.translator.addLocaleLoader((lang) => require(`locale/${lang}.json`));
      }
    },

    getSupportedLanguages() {
      const supportedLanguages = [TranslateJson.baseLanguage];
      if (TranslateJson.supportedLanguages) {
        for (const i in TranslateJson.supportedLanguages) {
          const lang = TranslateJson.supportedLanguages[i];
          if (supportedLanguages.indexOf(lang) === -1) {
            supportedLanguages.push(lang);
          }
        }
      }
      return supportedLanguages;
    },

    async loadLocale(defaultLocale) {
      let locale = localStorage.getItem('locale');
      if (locale === null || locale === 'null' || locale === 'undefined') {
        if (defaultLocale) {
          locale = defaultLocale;
        } else {
          locale = this.translator.getLocale();
        }
      }
      return this._setTranslatorLocale(locale);
    },
    attachEditor() {
      this.editor.attachLiveEditor(TranslateJson.projectId);
    },
    syncEditor: _.debounce(function () {
      if (this.editor) {
        // there is something to sync
        this.editor.syncHtmlState();
      }
    }, 50),

    async _setTranslatorLocale(locale) {
      // Check if language is supported
      if (this.getSupportedLanguages().indexOf(locale) === -1) {
        console.warn(`Language "${locale}" not supported, falling back to base language "${TranslateJson.baseLanguage}"`);
        // If not we fall back to the base language
        locale = TranslateJson.baseLanguage;
      }

      this.translator.setLocale(locale);
      this.translator.loadTranslations(locale);
      this.syncEditor();
    },

    async setLocale(locale) {
      localStorage.setItem('locale', locale);
      await this._setTranslatorLocale(locale);

      const ev = new LoadedEvent();
      ev.setOptions({
        locale,
      });
      ev.trigger();
    },

    getLocale() {
      return this.translator.getLocale();
    },

    translate(value, params, locale) {
      return this.translator.translate(value, params, locale);
    },

  });

  return new locale();
});
