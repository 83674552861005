define((require) => {
  const Backbone = require('backbone');

  return Backbone.Model.extend({
    defaults: {
      enabled: true,
      icon: null,
      alias: null,
      route: false,
      order: 0,
    },

    initialize() {
      const nodes = this.get('nodes');

      if (nodes) {
        const MenuItems = require('../collections/menuItems');

        this.nodes = new MenuItems();
        this.nodes.set(nodes);
      }
    },
  });
});
