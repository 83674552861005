define([
  'backbone',
  './../components/component',
  'jquery',
],
(Backbone, Component, $) => {
  const comp = Component.extend({
    /**
             *
             */
    initialize() {
      this.clean();
      this.indexRoute = '';
      this.indexFragment = '';
    },
    /**
             *
             * @param route
             */
    setIndex(route, fragment) {
      this.indexRoute = route;
      this.indexFragment = fragment || route;
    },
    /**
             *
             * @returns {string}
             */
    getIndexRoute() {
      return this.indexRoute;
    },
    /**
             *
             * @returns {string}
             */
    getIndexFragment() {
      return this.indexFragment;
    },
    /**
             * if curently on index
             * @returns {boolean}
             */
    onIndex() {
      return this.indexRoute === this.getCurrent();
    },
    /**
             * is route exists in path
             * @param route
             * @returns {boolean}
             */
    isInPath(route) {
      route = route || Backbone.history.route;
      return $.inArray(route, this.breadcrumbs) !== -1;
    },
    /**
             * clean add breadcrumbs
             */
    clean() {
      this.breadcrumbs = [];
      this.fragments = [];
      this.conditions = {};
    },
    /**
             * add route to end of breadcrumbs
             * @param route
             * @param condition
             * @param fragment optional will use current fragment (Backbone.history.fragment) if not set
             */
    add(route, condition, fragment) {
      fragment = typeof fragment === 'string' ? fragment : Backbone.history.fragment;
      if (this.getCurrentFragment() !== fragment) {
        this.breadcrumbs.push(route);
        this.fragments.push(fragment);
      }
      if (condition) {
        this.conditions[route] = condition;
      }
    },
    /**
             *
             */
    pop() {
      if (this.breadcrumbs.length) {
        this.breadcrumbs.pop();
        this.fragments.pop();
      }
    },
    /**
             * replaces last route to given one
             * @param route
             */
    replaceCurrent(route, changeHash) {
      this.pop();
      this.breadcrumbs.push(route);
      this.fragments.push(route);

      if (changeHash === undefined) {
        changeHash = true;
      }

      if (changeHash) {
        Backbone.history.navigate(route);
      }
    },
    /**
             * get current route
             */
    getCurrent() {
      if (this.breadcrumbs.length) {
        return this.breadcrumbs[this.breadcrumbs.length - 1];
      }
      return this.indexRoute;
    },
    /**
             * get current route
             */
    getCurrentFragment() {
      if (this.fragments.length) {
        return this.fragments[this.fragments.length - 1];
      }
      return this.indexFragment;
    },
    /**
             * Get the back route
             * @param options
             * @returns {*|string}
             */
    getBackRoute(options) {
      return this._getBackRouteForVars(
        this.breadcrumbs, this.fragments, this.conditions, options,
      );
    },
    /**
             *
             * @param breadcrumbs
             * @param fragments
             * @param conditions
             * @param options
             * @returns {string|*}
             * @private
             */
    _getBackRouteForVars(breadcrumbs, fragments, conditions, options) {
      options = options || {};
      if (!('skipSameRoute' in options)) {
        options.skipSameRoute = true;
      }

      let route = this.indexRoute;
      let fragment = this.indexFragment;
      const lastRoute = this.getCurrent();
      let returnEmpty = true;

      while (breadcrumbs.length) {
        route = breadcrumbs.pop();
        fragment = fragments.pop();

        if (fragment == Backbone.history.fragment) {
          continue; // current route
        }
        if (lastRoute == route && options.skipSameRoute) {
          continue; // same route
        }

        if (route in conditions) {
          // has condition
          if (typeof (conditions[route]) === 'function') {
            // is a function
            if (!conditions[route]()) {
              continue; // not allowed -> do to next
            }
            // is allowed
          }
        }
        returnEmpty = false;
        break;
      }

      if (returnEmpty) {
        return this.indexFragment;
      }

      return fragment;
    },
    /**
             * Get the back route
             * @param options
             * @returns {*|string}
             */
    peepBackRoute(options) {
      // clone
      const c = function (obj) {
        return JSON.parse(JSON.stringify(obj));
      };
      const breadcrumbs = c(this.breadcrumbs);
      const fragments = c(this.fragments);
      const conditions = c(this.conditions);
      return this._getBackRouteForVars(breadcrumbs, fragments, conditions, options);
    },
    /**
             * Navigate back
             * @param options
             * @returns {boolean}
             */
    goBack(options) {
      this.goto(this.getBackRoute(options));
      return false;
    },

    goto(path) {
      Backbone.history.navigate(path, { trigger: true });
    },

  });
  return new comp();
});
