define([
  'jquery',
  './behavior',
  'modules/common/crontabs/cron',
],
($, Behavior, CronTab) => Behavior.extend({

  initialize(options, view) {
    const crontab = view.crontab
                        || view.constructor.prototype.crontab
                        || {};
    $.each(crontab, (name, time) => {
      view.crontab[name] = new (CronTab.extend({
        cron: time,
        run() {
          if (typeof (view[name]) === 'function') {
            return view[name].apply(view);
          }
        },
      }))();
    });
  },
  onShow() {
    if (this.view.crontab) {
      $.each(this.view.crontab, (name, cron) => {
        cron.start();
      });
    }
  },
  onDestroy() {
    if (this.view.crontab) {
      $.each(this.view.crontab, (name, cron) => {
        cron.stop();
      });
    }
  },
}));
