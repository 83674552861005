define([
  'jquery',
  'module',
  'backbone',
  'application',
  '../../models/cache',

  'backbone.localstorage',
],
($, Module, Backbone, App, CacheModel) => {
  const store = Backbone.Marionette.Controller.extend({

    name: 'modules/common/components/cacheStore/localStorage',

    initialize(options) {
      options = options || {};
      const StoreCls = Backbone.Collection.extend({
        localStorage: new Backbone.LocalStorage(
          `[${App.settings.uuid}]${options.name}`,
        ),
        model: CacheModel,
      });

      this.maxAge = 100; // 100ms cache
      if (options.hasOwnProperty('maxAge')) {
        this.maxAge = options.maxAge;
      }
      this.store = new StoreCls();
      this.store.fetch();
    },
    /**
             *
             * @param model
             * @returns {boolean}
             */
    isFresh(model) {
      return this.maxAge === undefined
                    || model.get('created') + this.maxAge > new Date().getTime();
    },
    /**
             *
             * @param id
             * @returns {*}
             */
    lookup(id) {
      const def = $.Deferred();
      const self = this;
      const mod = this.store.get(id);
      if (mod) {
        def.resolve(mod);
      } else {
        def.reject('No cache found');
      }
      return def;
    },
    /**
             *
             * @param id
             * @param value
             */
    write(id, value) {
      const data = {
        id,
        value,
        created: new Date().getTime(),
      };
      const model = new CacheModel(data);
      const def = $.Deferred();
      try {
        this.store.set([model], { merge: true, add: true, remove: false })[0].save();
        def.resolve();
      } catch (e) {
        def.reject(e);
      }
      return def;
    },
    /**
             *
             * @param id
             */
    invalidate(id) {
      const def = $.Deferred();
      try {
        const model = this.store.get(id);
        if (model) {
          model.set({ created: 0 });
          model.save();
        }
        def.resolve();
      } catch (e) {
        def.reject(e);
      }
      return def;
    },
    /**
             *
             * @param id
             */
    purge(id) {
      const def = $.Deferred();
      try {
        const model = this.store.get(id);
        if (model) {
          model.destroy();
        }
        def.resolve();
      } catch (e) {
        def.reject(e);
      }
      return def;
    },
    /**
             *
             */
    cleanup() {
      const def = $.Deferred();
      try {
        if (this.store.length) {
          // clean the local storage
          // should use self.localStorage._clear() but broken
          const local = this.store.localStorage.localStorage();
          let { name } = this.store.localStorage;

          // Remove id-tracking item (e.g., "foo").
          local.removeItem(name);

          name = name.replaceAll('[', '\\[').replaceAll(']', '\\]');
          const itemRe = new RegExp(`^${name}-`);

          // Match all data items (e.g., "foo-ID") and remove.
          for (const k in local) {
            if (itemRe.test(k)) {
              local.removeItem(k);
            }
          }
        }
        this.store.reset();
        def.resolve();
      } catch (e) {
        def.reject(e);
      }
      return def;
    },
  });

  return store;
});
