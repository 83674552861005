define([
  'jquery',
  'underscore',
  'backbone',
  '../components/attributeSerialize',
  'modules/common/components/historyBreadcrumb',

  'backbone.marionette',
],
($, _, Backbone, Serial, HistoryBreadcrumb) => Backbone.Marionette.LayoutView.extend({

  regions: {
    view: '[js-region="swappable-item"]',
  },

  childEvents: {
    'parent:layout:swap': function (currentView, name, options) {
      this.triggerMethod('layout:swap', name, options);
    },
    'layout:swap': function (currentView, name, options) {
      this.swapView(name, options);
    },
    'navigation:changed': function (currentView, selectedId) {
      this.options.selectedId = selectedId;
    },
    'swappable:swapParent': function (currentView, target, swapTo, options) {
      this.swapParent(target, swapTo, options);
    },
  },
  /**
             * call on 2 views to proxy the events though them
             * @param from
             * @param to
             */
  proxySwappableEvents(from, to) {
    this.proxyEvents(from, to, ['layout:swap', 'swappable:swapParent']);
  },
  /**
             *
             * @param from
             * @param to
             * @param events
             */
  proxyEvents(from, to, events) {
    events.forEach((e) => {
      to.listenTo(from, e, function () {
        const args = _.toArray(arguments);
        args.unshift(e);
        to.triggerMethod.apply(to, args);
      });
    });
  },
  /**
             *
             * @returns {string}
             */
  template() {
    return '<div js-region="swappable-item"></div>';
  },
  /**
             *
             * @param options
             */
  initialize(options) {
    options = options || {};
    this.options = $.extend(true, options, this.defaults);

    this.mainView = null;
    this.views = {};
    this.currentViewName = null;

    this.options.swappableAttributes = Serial.parse(this.options.swappableAttributes);
  },
  /**
             *
             * @param name
             * @param cls
             * @param defaultOptions
             */
  setView(name, cls, defaultOptions) {
    this.views[name] = {
      name,
      cls,
      options: defaultOptions || {},
    };
  },
  /**
             *
             * @param cls
             * @param defaultOptions
             */
  setMainView(cls, defaultOptions) {
    this.mainView = {
      name: null,
      cls,
      options: defaultOptions || {},
    };
  },
  /**
             *
             * @param name
             * @param options
             */
  swapView(name, options) {
    let swapToView = false;
    if (name) {
      // non empty name
      if (name in this.views) {
        swapToView = this.views[name];
      }
    } else {
      // empty just load mainView
      if (this.mainView) {
        swapToView = this.mainView;
      }
    }
    if (swapToView) {
      this.currentViewName = name;
      const viewOptions = $.extend(true, {}, swapToView.options, options || {});
      this._doSwapView(name,
        swapToView,
        viewOptions);

      const path = this.getSwappablePath(name, viewOptions);
      if (path) {
        Backbone.history.navigate(path, { trigger: false });
        HistoryBreadcrumb.add(path);
      }
    }
  },
  /**
   * Extendable method to return custom paths
   */
  getSwappablePath(name, viewOptions) {
    let path = null;

    if (this.options.swappablePath) {
      path = this.options.swappablePath;
      if (name) {
        path += `/${name}`;
      }

      const params = Serial.stringify(
        this.options.swappableAttributes,
      );
      if (params) {
        path += `/${params}`;
      }

      if (this.options.swappablePathSuffix) {
        path += `/${this.options.swappablePathSuffix}`;
      }
    }

    return path;
  },
  /**
   * bubbled swap parent, for swappable in swappable
   *
   * in parent swappable
   * this.id = 'modules/shop/views/orders/view/swappable'
   *
   * usage in child swappable
   * this.triggerMethod('swappable:swapParent', 'modules/shop/views/orders/view/swappable');
   *
   * @param swapTo
   * @param options
   * @param target
   */
  swapParent(target, swapTo, options) {
    if (!!this.id && target === this.id) {
      this.swapView(swapTo, options);
    } else {
      this.triggerMethod('swappable:swapParent', target, swapTo, options);
    }
  },
  /**
             * Show the loader
             */
  showLoader() {
    const view = new Backbone.Marionette.ItemView({
      template() {
        return '<div class="loader"></div>';
      },
    });

    this.getRegion('view').show(view);
  },
  /**
             * creates view on fly and shows it in region
             * @param name
             * @param viewObj
             * @param options extra options for the view, will be merged
             * @private
             */
  _doSwapView(name, viewObj, options) {
    const region = this.getRegion('view');
    if (region) {
      region.reset();

      region.show(
        new (viewObj.cls)(options),
      );
    }
  },
  /**
             *
             */
  onShow() {
    // load main view
    this.swapView(this.options.swapTo || null);
  },
  /**
             * @return
             */
  getCurrentView() {
    const region = this.getRegion('view');
    return region.currentView;
  },
}));
