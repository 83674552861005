define([
  'jquery',
  'backbone',
  './data/countries',
  'modules/common/components/locale',
],
($, Backbone, Data, Locale) => {
  const countries = Backbone.Collection.extend({

    // Based on https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/zakendoen_met_het_buitenland/goederen_en_diensten_naar_andere_eu_landen/eu-landen_en_-gebieden/eu-landen_en_-gebieden
    // ISO2's taking from modules/common/collections/data/countries.json
    EUROPEAN_UNION_ISO2S: [
      'BE', // Belgium
      'BG', // Bulgaria
      'CY', // Cyprus
      'DK', // Denmark
      'DE', // Germany
      'EE', // Estonia
      'FI', // Finland
      'FR', // France
      'MC', // Monaco
      'GR', // Greece
      'HU', // Hungary
      'IE', // Ireland
      'IT', // Italy
      'HR', // Croatia
      'LV', // Latvia
      'LT', // Lithuania
      'LU', // Luxembourg
      'MT', // Malta
      'NL', // The Netherlands
      'AT', // Austria
      'PL', // Poland
      'PT', // Portugal
      'RO', // Romania
      'SI', // Slovenia
      'SK', // Slovakia
      'ES', // Spain
      'CZ', // Czech Republic
      'SE', // Sweden
      // The United Kingdom left the European Union on January 31, 2020. There is a transitional period until December 31, 2020. Nothing changes in practice during this period.
      // 'GB', // United Kingdom floated away -> it's 2021 now
    ],

    initialize() {
      this.add(Data);
    },

    isEuCountry(iso2) {
      return this.EUROPEAN_UNION_ISO2S.indexOf(iso2) > -1;
    },

    getTranslatedName(iso2) {
      const model = this.findWhere({ iso2 });
      if (undefined !== model) {
        return Locale.translate(model.get('key'));
      }
      return iso2;
    },

    nameContains(text) {
      return this.filter((model) =>
        // indexOf(-1) -> not found -> return 0 -> false
        // other are true
        ~model.get('name').toLowerCase().indexOf(text.toLowerCase()));
    },
  });
  return new countries();
});
