define([
  './behavior',
  'underscore',
],
(Behavior, _) => Behavior.extend({
  onShow() {
    return _.each(this.view.regions, (function (_this) {
      return function (region, regionName) {
        let viewClass; let
          viewOptions;
        if (!region.viewClass) {
          return;
        }
        viewClass = region.viewClass;
        viewOptions = _.result(region, 'viewOptions') || {};
        return _this.view.getRegion(regionName).show(new viewClass(viewOptions));
      };
    }(this)));
  },
}));
