define([
  'jquery',
  './../components/component',
  'communicator',
],
($, Component, Communicator) => Component.extend({
  events: [],

  initialize() {
    this.bind();
  },

  bind() {
    const self = this;

    $.each(this.events, function () {
      const name = new this.event().getName();
      Communicator.mediator.bind(name, this.action, self);
    });
  },

  unbind() {
    const self = this;

    $.each(this.events, function () {
      const name = new this.event().getName();
      Communicator.mediator.unbind(name, this.action, self);
    });
  },
}));
