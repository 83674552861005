define([
  'underscore',
  'backbone',
  'modules/common/acls/acl',
],
(_, Backbone, Acl) => Acl.extend({

  initialize(options) {
    Acl.prototype.initialize.call(this, options);
    let routes = options.routes || [];

    if (_.isString(routes) || _.isRegExp(routes)) {
      routes = [routes];
    }

    this.routes = routes;

    let errorFn = options.errorCallback;

    if (!_.isFunction(errorFn)) {
      errorFn = function (routes) { console.warn(`${routes} has an error`); };
    }

    this.errorFn = errorFn;
  },

  authorized() {
    let authorized = false;

    const currentRoute = Backbone.history.getFragment();
    _.each(this.routes, (route) => {
      // First we check if the route was not already authorized.
      if (!authorized) {
        // Check if the string matches
        if (_.isString(route)) {
          authorized = route === currentRoute;
        }
        // Check if the regex matches
        else if (_.isRegExp(route)) {
          authorized = route.test(currentRoute);
        }
      }
    });

    return authorized;
  },

  error(route) {
    this.errorFn(route);
  },
}));
