define([
  'jquery',
  'backbone',
  'application',
  './component',
  '../events/connection/online',
  '../events/connection/offline',

  'timer.js',
],
($, Backbone, App, Component, OnlineEvent, OfflineEvent, Timer) => {
  let isOnline = true;

  const comp = Component.extend({

    UNKNOWN: 'unknown', // The user agent has established a network connection, but is unable to determine what is the underlying connection technology.
    ETHERNET: 'ethernet',
    WIFI: 'wifi',
    CELL_2G: '2g',
    CELL_3G: '3g',
    CELL_4G: '4g',
    CELL: 'cellular',
    NONE: 'none',
    WIMAX: 'wimax',
    OTHER: 'other', // The user agent is using a connection type that is not one of enumerated connection types as the underlying connection technology.

    initialize(options) {
      this.type = this.UNKNOWN;
      this.onlineCheckUrl = window.location.href;
      this.onlineCheckRetryTime = 15; // 5 secs
      this.onlineDef = {};
    },
    /**
             *
             * @returns {boolean}
             */
    isOnline() {
      return isOnline;
    },
    /**
             *
             * @returns {boolean}
             */
    isOffline() {
      return !isOnline;
    },
    /**
             * @param {boolean} online
             * @param {int} type one of the connection constants
             */
    changeStatus(online, type) {
      online = !!online; // convert to bool
      if (type) {
        // type is set
        if (type == this.NONE && online) {
          throw new Error('Type can`t be none with online status');
        }
        this.type = type;
      }

      let event = null;
      if (!isOnline && online) {
        // going online
        event = new OnlineEvent();
      } else if (isOnline && !online) {
        // going offline
        event = new OfflineEvent();
      }

      isOnline = online;

      if (event) {
        event.trigger();
      }
    },
    /**
             *
             * @param type
             */
    changeStatusFromType(type) {
      if (!type) {
        throw new Error('Type can`t be empty');
      }

      if (this.NONE !== type) {
        this.changeStatus(true, type);
      } else {
        this.changeStatus(false, type);
      }
    },
    /**
             *
             * @param success
             * @param error
             * @param timeout
             * @param url
             * @returns {*}
             */
    checkOnline(success, error, timeout, url) {
      const self = this;
      const s = this.onlineCheckRetryTime;
      url = url || this.onlineCheckUrl;

      if (!(url in this.onlineDef)) {
        const def = this.onlineDef[url] = $.Deferred();
        if (timeout > 0) {
          new Timer({
            onend() {
              delete self.onlineDef[url];
              def.reject();
            },
          }).start(timeout);
        }
        let tm = s * 500;
        if (tm < 1000) {
          tm = 1000;
        }
        if (tm > 5000) {
          tm = 5000;
        }
        self.checkCall(def, s, tm, url, error);
      }
      if (success) {
        this.onlineDef[url].then(success);
      }
      return this.onlineDef[url].promise();
    },

    checkCall(def, s, tm, url, error) {
      const self = this;
      $.ajax(url, {
        cache: false,
        method: 'HEAD',
        timeout: tm,
        success() {
          delete self.onlineDef[url];
          self.changeStatusFromType(self.ETHERNET);
          def.resolve();
        },
        error() {
          self.changeStatusFromType(self.NONE);
          if (error) {
            error();
          }
          new Timer({
            onend() {
              self.checkCall(def, s, tm, url, error);
            },
          }).start(s);
        },
      });
    },
  });

  return new comp();
});
