define([
  '../hashTable',
  'underscore',
  '../../models/configuration',
],
(HashTable, _, Configuration) => {
  /**
         * Configuration manager
         */
  const manager = function () {
    const self = this;
    this.map = new HashTable();
    this.get = function (name, key) {
      var name = name || 'main';

      if (!self.map.has(name)) {
        self.map.add(name, new Configuration());
      }
      const config = self.map.get(name);
      if (key !== undefined) {
        return config.get(key);
      }
      return config;
    };
    this.set = function (name, object) {
      return this.get(name).set(object);
    };
  };

  /**
         * Name of the manager
         * @type {string}
         */
  manager.prototype.name = 'common/components/managers/configuration';

  /**
         * Return the manager
         */
  return manager;
});
