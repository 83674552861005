define(['hbs/handlebars'], (Handlebars) => {
  function email(maybeEmail) {
    if (typeof maybeEmail !== 'string') {
      return '-';
    } if (maybeEmail.startsWith('nomail+')) {
      return '-';
    }
    return maybeEmail;
  }
  Handlebars.registerHelper('common$email', email);

  return email;
});
