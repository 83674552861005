define(['hbs/handlebars', 'modules/common/components/locale', 'modules/common/components/moment'], (Handlebars, Locale, Moment) => {
  function compare(datetime, format, locale) {
    format = format || 'LL';

    if (typeof locale !== 'string') {
      locale = Locale.getLocale();
    }
    if (datetime) {
      var m = Moment(datetime);
    } else {
      var m = Moment();
    }
    return m.locale(locale).format(format);
  }

  Handlebars.registerHelper('common$datetime', compare);
  return compare;
});
