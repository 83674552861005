define([
  'backbone',
  'modules/common/components/string',
],
(Backbone) => {
  const comp = Backbone.Marionette.Controller.extend({

    /**
             * Convert base64 string to a blob
             * @param b64Data
             * @param contentType
             * @param sliceSize
             * @returns {Blob}
             */
    fromBase64(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      if (b64Data.startsWith('data:')) {
        const splitted = b64Data.split('base64,');

        b64Data = splitted[1];
        contentType = splitted[0].split(':')[1].split(';')[0];
      }

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  });

  return new comp();
});
