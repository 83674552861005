define([
  'application',
  'modules/common/components/component',
  'less',
],
(App, Component, Less) => {
  const less = Component.extend({
    compile(file, variables) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/less';
      link.href = file;

      Less.sheets.push(link);
      Less.modifyVars(variables);
    },
  });

  return new less();
});
