define([
  'module',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/common/components/idle',
],
(Module, Backbone, AppLocalStorage, Idle) => Backbone.Model.extend({
  /**
             *
             */
  fetchId: 1,
  /**
             * Store our users in localstorage
             */
  localStorage: AppLocalStorage.get('common/model/idle'),
  /**
             * When initialized fetch
             */
  initialize() {
    this.fetch();
  },
  /**
             *
             */
  getLastActive() {
    if (!this.has('lastActive')) {
      this.set('lastActive', Idle.getLastActive());
      this.save();
    } else {
      const lastActive = Idle.getLastActive();
      const modelLastActive = new Date(this.get('lastActive'));
      if (lastActive > modelLastActive) {
        this.set('lastActive', lastActive);
        this.save();
      }
    }
    return new Date(this.get('lastActive'));
  },
  /**
             *
             * @returns {{id: (number|*)}}
             */
  defaults() {
    return {
      id: this.fetchId,
    };
  },
}));
