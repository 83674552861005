define([
  'jquery',
  'module',
  'backbone',
  'modules/common/views/swappable',
],
($, Module, Backbone, SwappableView) => SwappableView.extend({

  childEvents: $.extend({
    'swappable:reloadViewStorage': 'reloadViewStorage',
    'swappable:reloadSharedStorage': 'reloadSharedStorage',
  }, SwappableView.prototype.childEvents),

  initialize(options) {
    this.swappableDataStorage = {};
    this.swappableDataStorageSharedLoaded = false;
    this.swappableDataStorageLoaded = {};

    SwappableView.prototype.initialize.call(this, options);
  },

  /**
             *
             * @param from
             * @param to
             */
  proxySwappableEvents(from, to) {
    this.proxyEvents(from, to, ['swappable:reloadViewStorage', 'swappable:reloadSharedStorage']);
    SwappableView.prototype.proxySwappableEvents.call(this, from, to);
  },

  reloadViewStorage(view, name) {
    name = name || null; // unification of js null values
    this.swappableDataStorageLoaded[name] = false;
  },

  reloadSharedStorage() {
    this.swappableDataStorageSharedLoaded = false;
  },

  _doSwapView(name, viewObj, options) {
    name = name || null; // unification of js null values
    this.loadCollectionsOptions = options || {}; // for BC

    const self = this;
    const isLoaded = !!self.swappableDataStorageLoaded[name] && !options.reload;

    const sharedLoader = self.swappableDataStorageSharedLoaded
      ? true : self.loadSharedData(self.swappableDataStorage);

    self.showLoader();
    $.when(sharedLoader).then(() => {
      self.swappableDataStorageSharedLoaded = true;

      const viewLoader = isLoaded
        ? true : self.loadDataForView(name, options, self.swappableDataStorage);
      $.when(viewLoader).then(() => {
        self.swappableDataStorageLoaded[name] = true;
        self.applyOptionsForSwap(name, options, self.swappableDataStorage);
        SwappableView.prototype._doSwapView.call(self, name, viewObj, options);
      }, console.error);
    }, console.error);
  },

  /**
             * apply the option data from data storage, bu default all will be applied
             * @override this if you want some extra options from dataStorage passed to
             * @param name
             * @param childOptions
             * @param dataStorage
             * @returns {*}
             */
  applyOptionsForSwap(name, childOptions, dataStorage) {
    $.each(dataStorage, (i, o) => {
      childOptions[i] = o;
    });
    return childOptions;
  },
  /**
             * Save data in dataStorage.
             * @override this if you want custom loading for view
             * @param name
             * @param childOptions
             * @param dataStorage
             * @returns {*}
             */
  loadDataForView(name, childOptions, dataStorage) {
    const loaderName = name ? `${name}Loader` : 'mainLoader';
    if (typeof (this[loaderName]) === 'function') {
      // handling function way of defining the loaders
      console.warn('modules/common/views/swappableWithLoading::*Loader is depracated, please use loadDataForView instead');
      return this[loaderName](childOptions, dataStorage);
    }
    return $.Deferred().resolve().promise();
  },
  /**
             * @override this if you have some shared data to be loaded for all views
             * @param options
             * @param dataStorage
             * @returns {*}
             */
  loadSharedData(dataStorage) {
    if (typeof (this.loadCollections) === 'function') {
      // using depracated function for BC
      console.warn('modules/common/views/swappableWithLoading::loadCollections is depracated, please use loadSharedData instead');
      return this.loadCollections(this.loadCollectionsOptions);
    }
    return $.Deferred().resolve().promise();
  },
}));
