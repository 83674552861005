define([
  'module',
  'backbone',
],
(Module, Backbone) => Backbone.Model.extend({

  idAttribute: 'id',

  defaults() {
    return {
      created: new Date().getTime(),
    };
  },
}));
