define([
  'jquery',
  'module',
  'backbone',
  'application',
  'underscore',
  '../../models/cache',
],
($, Module, Backbone, App, _, CacheModel) => {
  let db; // static db connection

  const store = Backbone.Marionette.Controller.extend({

    name: 'modules/common/components/cacheStore/webSqlStorage',

    initialize(options) {
      options = options || {};

      this.maxAge = 100; // 100ms cache
      if (options.hasOwnProperty('maxAge')) {
        this.maxAge = options.maxAge;
      }
      this.debug = !!options.debug;
      this.tableName = `cache_${options.name}`;
      this._executeSql(
        `CREATE TABLE IF NOT EXISTS ${this.tableName
        } ( \`id\` TEXT PRIMARY KEY, \`value\` TEXT, \`created\` INTEGER );`,
      );
      this.serializer = options.serializer || {
        serialize(item) {
          return _.isObject(item) ? JSON.stringify(item) : item;
        },
        // fix for "illegal access" error on Android when JSON.parse is passed null
        deserialize(data) {
          return data && JSON.parse(data);
        },
      };
    },
    /**
             *
             */
    getConnection() {
      if (!db) {
        db = this.initConnection();
      }
      return db;
    },
    /**
             *
             * @returns {Database}
             */
    initConnection() {
      const { uuid } = App.settings;
      return openDatabase(`cache_${uuid}`,
        '1.0',
        `App ${uuid} DB`,
        5 * 1024 * 1024);
    },
    /**
             *
             * @param model
             * @returns {boolean}
             */
    isFresh(model) {
      return this.maxAge === undefined
                    || model.get('created') + this.maxAge > new Date().getTime();
    },
    /**
             *
             * @param id
             * @returns {*}
             */
    lookup(id) {
      const def = $.Deferred();
      const self = this;
      this._executeSql(`SELECT id, value, created FROM ${this.tableName} WHERE( id =? );`,
        [id],
        (tx, res) => {
          if (res.rows.length) {
            // cache found
            const result = res.rows.item(0);
            const mod = new CacheModel({
              id: result.id,
              value: self.serializer.deserialize(result.value),
              created: result.created,
            });
            def.resolve(mod);
          } else {
            // cache not found
            def.reject('No cache found');
          }
        },
        def.reject);
      return def;
    },
    /**
             *
             * @param id
             * @param value
             */
    write(id, value) {
      return this._executeSql(
        `INSERT OR REPLACE INTO \`${this.tableName}\` (id, value, created) VALUES (?,?,?);`,
        [
          id,
          this.serializer.serialize(value),
          new Date().getTime(),
        ],
      );
    },
    /**
             *
             * @param id
             */
    invalidate(id) {
      return this._executeSql(
        `UPDATE \`${this.tableName}\` SET created = 0 WHERE id = ?;`,
        [id],
      );
    },
    /**
             *
             * @param id
             */
    purge(id) {
      return this._executeSql(
        `DELETE FROM \`${this.tableName}\` WHERE id = ?;`,
        [id],
      );
    },
    /**
             *
             */
    cleanup() {
      return this._executeSql(`DROP TABLE \`${this.tableName}\`;`);
    },
    /**
             *
             * @param SQL
             * @param params
             * @param successCallback
             * @param errorCallback
             * @private
             */
    _executeSql(SQL, params, successCallback, errorCallback) {
      // make sure the connection is initialized
      const conn = this.getConnection();

      const self = this; const
        def = $.Deferred();
      params = params || [];
      const success = function (tx, result) {
        if (self.debug) {
          console.log(SQL, params, ' - finished');
        }
        if (successCallback) {
          successCallback(tx, result);
        }
        def.resolve(tx, result);
      };
      const error = function (tx, error) {
        if (self.debug) {
          console.error(SQL, params, ` - error: ${error}`);
        }
        if (errorCallback) {
          return errorCallback(error, tx);
        }
        def.reject(error, tx);
      };

      conn.transaction((tx) => {
        tx.executeSql(SQL, params, success, error);
      });
      return def;
    },
  });

  return store;
});
