define([
  './hashTable',
  'underscore',
],
(HashTable, _) => HashTable.extend({
  /**
             * Returns the object, uses the name in the prototype
             * @param obj
             * @returns {*}
             */
  getByObj(obj) {
    const { name } = obj.prototype;

    if (this.has(name)) {
      return this.get(name);
    }
    return this.add(name, new obj());
  },
}));
