define([
  'jquery',
  'underscore',
  'hbs/handlebars',
  'markdown-it',
],
($, _, Handlebars, MarkdownIt) => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  function getUuid() {
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  function render(markdown, maxHeight) {
    markdown = markdown || '';
    // When the 2nd parameter is not set, this is some object from HandleBars
    if (_.isObject(maxHeight)) {
      maxHeight = false;
    }
    const markdownIt = new MarkdownIt();
    const uuid = getUuid();
    const html = markdownIt.render(markdown);

    // as we can not set the (iframe) body with html we set it afterwards (after the return) with javascript.
    setTimeout(() => {
      // getting the iframe on the page;
      const $iframe = $(`iframe#${uuid}`);
      const $content = $iframe.contents();
      const $body = $content.find('body');
      const $head = $content.find('head');
      // Creating container div, so we can get the height later.
      const $div = $('<div>');
      $div.html(html);
      $div.find('p').css('white-space', 'pre-line');
      $div.find('a').removeAttr('href'); // Disables links

      // To make sure wide images are not expanding outside the iframe
      const $images = $div.find('img');
      $images.css('width', '100%');

      const $divChildren = $div.children();
      $divChildren.first().css('margin-top', '0');
      $divChildren.last().css('margin-bottom', '0');

      $body.html($div);
      $body.css('margin', 0); // Default it adds some margin to the body.

      const $style = $('<style>')
        .text(
          `body {
                        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        font-size: 13px;
                        color: #323d40;
                    }`,
        );

      $head.html($style);

      if (maxHeight) {
        $iframe.css('max-height', maxHeight); // So it doesn't become huge
      }

      // Resizing the height
      const resizeFn = function () {
        let divHeight = $div.outerHeight();
        $iframe.css('height', `${divHeight}px`);

        /**
                     * For some reason, applying the height twice results in the correct divHeight.
                     * The first time it does not has the correct height.
                     * But the second time it does has the correct height.
                     */
        divHeight = $div.outerHeight();
        $iframe.css('height', `${divHeight}px`);
      };

      // Prevent jumping.
      resizeFn();

      // Resizes as images load in.
      $images.on('load', () => resizeFn());

      // Giving the view some time to render and then re-size it.
      setTimeout(resizeFn, 10);
      setTimeout(resizeFn, 500); // 500 is needed for modals. An iframe does not render in a modal that has a transition effect.
    }, 0);

    return new Handlebars.SafeString(
      // return iframe with unique uuid, full width so it looks nicer by default.
      `<iframe style="width: 100%" id="${uuid}"></iframe>`,
    );
  }

  Handlebars.registerHelper('markdown$render', render);
  return render;
});
