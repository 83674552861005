define([
  'backbone',
  '../component',

  'backbone.deepmodel',
],
(Backbone, Component) => {
  const comp = Component.extend({
    /**
             *
             */
    initialize() {
      const CollCls = Backbone.Collection.extend({
        model: Backbone.DeepModel,
      });
      this.collection = new CollCls();
    },
    /**
             *
             * @param layoutName
             * @param values
             * @returns Backbone.DeepModel
             */
    set(layoutName, values) {
      values = values || {};
      if ('id' in values) {
        throw new Error('id cannot be set as layout option');
      }
      let m = this.get(layoutName);
      if (!m) {
        // no model yet
        values.id = layoutName;
        m = this.collection.add(values);
      } else {
        m.set(values);
      }
      return m;
    },
    /**
             *
             * @param layoutName
             * @returns {*}
             */
    get(layoutName) {
      return this.collection.get(layoutName);
    },
    /**
             *
             * @param layoutName
             * @returns {*}
             */
    getData(layoutName) {
      const m = this.get(layoutName);
      if (!m) {
        return {};
      }
      const data = m.toJSON();
      delete data.id;
      return data;
    },
  });
  return new comp();
});
