define([
  'backbone',
],
(Backbone) => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  const comp = Backbone.Marionette.Controller.extend({
    /**
             *
             * @returns {string}
             */
    genRandom() {
      return `${s4() + s4()}-${s4()}-4${s4().substring(1)}-${
        s4()}-${s4()}${s4()}${s4()}`;
    },
  });
  return new comp();
});
